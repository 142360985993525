const SPACE_DATA = {
		"u6983309":  [{space_js: "//hhaabb.hanganan.com/source/ql_ktn_d/dr/production/t.js", space_src: "baidujs", space_type: "inters", space_id: "u6983309"}],
		"u6983310":  [{space_js: "//hhaabb.hanganan.com/production/i/d/cl/resource/fvv/source/mj.js", space_src: "baidujs", space_type: "native", space_id: "u6983310"}],
		"u6983311":  [{space_js: "//hhaabb.hanganan.com/production/j/e/common/dm/gw/openjs/wnn.js", space_src: "baidujs", space_type: "native", space_id: "u6983311"}],
		"u6983312":  [{space_js: "//hhaabb.hanganan.com/common/k-fenh/source/x/x/resource/ou.js", space_src: "baidujs", space_type: "native", space_id: "u6983312"}],
		"u6983313":  [{space_js: "//hhaabb.hanganan.com/source/lg_f/resource/oiyyp/openjs/y.js", space_src: "baidujs", space_type: "native", space_id: "u6983313"}],
		"u6983314":  [{space_js: "//hhaabb.hanganan.com/site/m_h/g/static/p_jzzqd.js", space_src: "baidujs", space_type: "inters", space_id: "u6983314"}],
		"u6983315":  [{space_js: "//hhaabb.hanganan.com/site/n/ihqk-a/source/arf.js", space_src: "baidujs", space_type: "native", space_id: "u6983315"}],
		"u6983316":  [{space_js: "//hhaabb.hanganan.com/source/ojir/resource/lb-bs/i.js", space_src: "baidujs", space_type: "native", space_id: "u6983316"}],
		"u6983317":  [{space_js: "//hhaabb.hanganan.com/production/pk_jsmc/common/ct/resource/l.js", space_src: "baidujs", space_type: "native", space_id: "u6983317"}],
		"u6983318":  [{space_js: "//hhaabb.hanganan.com/site/q/openjs/lk-t/source/ndd-un.js", space_src: "baidujs", space_type: "native", space_id: "u6983318"}],
};
export const SPACE_MAP = {
	HomeInter:  SPACE_DATA["u6983309"],
	HomeBanner:  SPACE_DATA["u6983310"],
	HomeNative:  SPACE_DATA["u6983311"],
	HomeNativeCenter1:  SPACE_DATA["u6983312"],
	HomeNativeCenter2:  SPACE_DATA["u6983313"],
	RecipeDetailBanner:  SPACE_DATA["u6983310"],
	RecipeDetailNative:  SPACE_DATA["u6983311"],
	TopicDetailBanner:  SPACE_DATA["u6983310"],
	TopicDetailNative:  SPACE_DATA["u6983311"],
	BaikeDetailBanner:  SPACE_DATA["u6983310"],
	BaikeDetailNative:  SPACE_DATA["u6983311"],
	SolarDetailBanner:  SPACE_DATA["u6983310"],
	ResultInter:  SPACE_DATA["u6983309"],
	AnswerInter:  SPACE_DATA["u6983309"],
	ListInter:  SPACE_DATA["u6983309"],
	ListBanner:  SPACE_DATA["u6983310"],
	ListNative:  SPACE_DATA["u6983311"],
	ListNativeCenter1:  SPACE_DATA["u6983312"],
	ListNativeCenter2:  SPACE_DATA["u6983313"],
}
export const ALL_SPACE_MAP = {
    HNW_HAMS_VO_7 : {
		HomeInter:  SPACE_DATA["u6983309"],
		HomeBanner:  SPACE_DATA["u6983310"],
		HomeNative:  SPACE_DATA["u6983311"],
		HomeNativeCenter1:  SPACE_DATA["u6983312"],
		HomeNativeCenter2:  SPACE_DATA["u6983313"],
		RecipeDetailBanner:  SPACE_DATA["u6983310"],
		RecipeDetailNative:  SPACE_DATA["u6983311"],
		TopicDetailBanner:  SPACE_DATA["u6983310"],
		TopicDetailNative:  SPACE_DATA["u6983311"],
		BaikeDetailBanner:  SPACE_DATA["u6983310"],
		BaikeDetailNative:  SPACE_DATA["u6983311"],
		SolarDetailBanner:  SPACE_DATA["u6983310"],
		ResultInter:  SPACE_DATA["u6983309"],
		AnswerInter:  SPACE_DATA["u6983309"],
		ListInter:  SPACE_DATA["u6983309"],
		ListBanner:  SPACE_DATA["u6983310"],
		ListNative:  SPACE_DATA["u6983311"],
		ListNativeCenter1:  SPACE_DATA["u6983312"],
		ListNativeCenter2:  SPACE_DATA["u6983313"],
    },
    HNW_HAMS_VO_8 : {
		HomeInter:  SPACE_DATA["u6983314"],
		HomeBanner:  SPACE_DATA["u6983315"],
		HomeNative:  SPACE_DATA["u6983316"],
		HomeNativeCenter1:  SPACE_DATA["u6983317"],
		HomeNativeCenter2:  SPACE_DATA["u6983318"],
		RecipeDetailBanner:  SPACE_DATA["u6983315"],
		RecipeDetailNative:  SPACE_DATA["u6983316"],
		TopicDetailBanner:  SPACE_DATA["u6983315"],
		TopicDetailNative:  SPACE_DATA["u6983316"],
		BaikeDetailBanner:  SPACE_DATA["u6983315"],
		BaikeDetailNative:  SPACE_DATA["u6983316"],
		SolarDetailBanner:  SPACE_DATA["u6983315"],
		ResultInter:  SPACE_DATA["u6983314"],
		AnswerInter:  SPACE_DATA["u6983314"],
		ListInter:  SPACE_DATA["u6983314"],
		ListBanner:  SPACE_DATA["u6983315"],
		ListNative:  SPACE_DATA["u6983316"],
		ListNativeCenter1:  SPACE_DATA["u6983317"],
		ListNativeCenter2:  SPACE_DATA["u6983318"],
    },
    DEFAULT : {
		HomeInter:  SPACE_DATA["u6983309"],
		HomeBanner:  SPACE_DATA["u6983310"],
		HomeNative:  SPACE_DATA["u6983311"],
		HomeNativeCenter1:  SPACE_DATA["u6983312"],
		HomeNativeCenter2:  SPACE_DATA["u6983313"],
		RecipeDetailBanner:  SPACE_DATA["u6983310"],
		RecipeDetailNative:  SPACE_DATA["u6983311"],
		TopicDetailBanner:  SPACE_DATA["u6983310"],
		TopicDetailNative:  SPACE_DATA["u6983311"],
		BaikeDetailBanner:  SPACE_DATA["u6983310"],
		BaikeDetailNative:  SPACE_DATA["u6983311"],
		SolarDetailBanner:  SPACE_DATA["u6983310"],
		ResultInter:  SPACE_DATA["u6983309"],
		AnswerInter:  SPACE_DATA["u6983309"],
		ListInter:  SPACE_DATA["u6983309"],
		ListBanner:  SPACE_DATA["u6983310"],
		ListNative:  SPACE_DATA["u6983311"],
		ListNativeCenter1:  SPACE_DATA["u6983312"],
		ListNativeCenter2:  SPACE_DATA["u6983313"],
    },
};
